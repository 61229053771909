// Angular Imports
import { Component, signal, ChangeDetectionStrategy, inject, computed } from '@angular/core'
// Angular Material Imports
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog'
// Shared Imports
import {
	ButtonComponent,
	LinkComponent,
	IconComponent,
	ButtonIconComponent,
	ImageComponent,
	TileComponent,
} from '@shared/components/independents'
import { Icons, TranslocoLanguageKey, UiTheme } from '@shared/models'
import { UiStore } from '@shared/store'
// Thirdparty Imports
import { TranslocoDirective } from '@jsverse/transloco'

const components = [
	LinkComponent,
	IconComponent,
	ButtonIconComponent,
	ButtonComponent,
	ImageComponent,
	TileComponent,
]

const material = [MatDialogModule]

@Component({
	selector: 'gld-contactme-dialog',
	standalone: true,
	imports: [TranslocoDirective, ...material, ...components],
	templateUrl: './contactme-dialog.component.html',
	styleUrl: './contactme-dialog.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactmeDialogComponent {
	readonly #dialogRef = inject(MatDialogRef<ContactmeDialogComponent>)
	readonly #uiStore = inject(UiStore)

	readonly UiTheme = UiTheme
	readonly Icons = Icons
	readonly TranslocoLanguageKey = TranslocoLanguageKey

	theme = computed<UiTheme>(() => this.#uiStore.theme())
	language = computed<TranslocoLanguageKey>(() => this.#uiStore.language())
	socials = signal<Social[]>([
		{
			name: 'LinkedIn',
			link: 'https://www.linkedin.com/in/iamgld/',
			icon: Icons.linkedinBoxFill,
			username: 'iamgld',
		},
		{
			name: 'GitHub',
			link: 'https://github.com/iamgld',
			icon: Icons.githubFill,
			username: 'iamgld',
		},
		{
			name: 'Instagram',
			link: 'https://www.instagram.com/iamgld_',
			icon: Icons.instagramFill,
			username: 'iamgld_',
		},
		{
			name: 'Twitter',
			link: 'https://twitter.com/iamgld_',
			icon: Icons.twitterFill,
			username: 'iamgld_',
		},
		{
			name: 'Facebook',
			link: 'https://www.facebook.com/iamgld',
			icon: Icons.facebookCircleFill,
			username: 'iamgld',
		},
	])

	openPdf(language: TranslocoLanguageKey): void {
		if (language === TranslocoLanguageKey.english)
			window.open('assets/pdfs/curriculum/iamgld-cv-english.pdf', '_blank')
		else window.open('assets/pdfs/curriculum/iamgld-cv-spanish.pdf', '_blank')
	}

	closeDialog(): void {
		this.#dialogRef.close()
	}
}

interface Social {
	name: string
	icon: Icons
	link: string
	username: string
}

