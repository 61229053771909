<footer class="Footer" [class.Theme--dark]="theme() === UiTheme.dark" *transloco="let transloco; scope: 'shared'">
	<section class="Footer-contacts">
		<section class="Footer-contacts-item">
			<!-- English -->
			@if (language() === TranslocoLanguageKey.english) {
			@for (contact of contactsEnglish(); track contact) {
			<ng-container [ngTemplateOutlet]="contactItemTemplate" [ngTemplateOutletContext]="{ $implicit: contact }" />
			}
			}
			<!-- Spanish -->
			@if (language() === TranslocoLanguageKey.spanish) {
			@for (contact of contactsSpanish(); track contact) {
			<ng-container [ngTemplateOutlet]="contactItemTemplate" [ngTemplateOutletContext]="{ $implicit: contact }" />
			}
			}
		</section>
	</section>

	<section class="Footer-brand">
		<gld-link redirect="/" type="decorated" [theme]="theme()">
			<span class="Footer-brand-copy">iamgld</span>
		</gld-link>
	</section>

	<section class="Footer-socials">
		@for (social of socials(); track social) {
		<gld-link external [redirect]="social.link">
			<gld-button-icon iconSize="medium" [name]="social.name" [theme]="theme()" [icon]="social.icon" />
		</gld-link>
		}
	</section>

	<p class="Footer-copyright">
		{{ transloco('shared.components.compounds.footer.copyright', { year: currentYear() }) }}
	</p>
</footer>

<!-- Contact Item Template -->
<ng-template #contactItemTemplate let-contact>
	<gld-link external type="decorated" [redirect]="contact.link" [theme]="theme()">
		<gld-icon gldIcon [icon]="contact.icon" size="tiny" space="right" />
		<span>{{ contact.copy }}</span>
	</gld-link>
</ng-template>