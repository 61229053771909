<nav class="Sidenav" [class.Theme--dark]="theme() === UiTheme.dark">
	<section class="Sidenav-icons">
		<!-- Close button -->
		<gld-button-icon active name="Close" iconSize="normal" [theme]="theme()" [icon]="Icons.closeLine"
			(clicked)="closeSidenav()" />
	</section>

	<section class="Sidenav-items">
		@for (navbarItem of navbarItems(); track navbarItem) {
		@if (navbarItem?.hasSubitems) {
		<section aria-hidden="true" class="Sidenav-item" [class.Sidenav-item--opened]="navbarItem?.opened"
			(keyup)="toggleNavbarItem(navbarItem)" (click)="toggleNavbarItem(navbarItem)">
			<gld-icon gldIcon size="small" class="Sidenav-item-prefix" [icon]="navbarItem.icon" [moveTopToBotton]="-1" />

			<span>{{ navbarItem.label }}</span>

			<gld-icon gldIcon size="small" class="Sidenav-item-suffix" [icon]="Icons.arrowDownSLine"
				[@smoothRotation]="navbarItem.opened ? '0deg' : '180deg'" />
		</section>

		<section class="Sidenav-subitems" [@smoothCollapse]="navbarItem.opened ? 'show' : 'hidden'">
			@for (navbarSubitem of navbarItem?.subitems; track navbarSubitem) {
			<section aria-hidden="true" class="Sidenav-item" [class.Sidenav-item--active]="activeRoute(navbarSubitem.route)"
				(keyup)="onNavigate(navbarSubitem.route)" (click)="onNavigate(navbarSubitem.route)">
				<gld-icon gldIcon size="small" class="Sidenav-item-prefix" [icon]="navbarSubitem.icon" [moveTopToBotton]="-1" />

				<span>{{ navbarSubitem.label }}</span>
			</section>
			}
		</section>
		} @else {
		<section aria-hidden="true" class="Sidenav-item" [class.Sidenav-item--active]="activeRoute(navbarItem.route)"
			(keyup)="onNavigate(navbarItem.route)" (click)="onNavigate(navbarItem.route)">
			<gld-icon gldIcon size="small" class="Sidenav-item-prefix" [icon]="navbarItem.icon" [moveTopToBotton]="-1" />

			<span>{{ navbarItem.label }}</span>
		</section>
		}
		}
	</section>
</nav>