// Angular Imports
import { ChangeDetectionStrategy, Component, booleanAttribute, input } from '@angular/core'
// Shared Imports
import { UiTheme } from '@shared/models'

@Component({
	selector: 'gld-quotes',
	standalone: true,
	imports: [],
	templateUrl: './quotes.component.html',
	styleUrl: './quotes.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuotesComponent {
	readonly UiTheme = UiTheme
	readonly EQuotesBorderDirection = EQuotesBorderDirection
	readonly QuotesAlignmentDirection = QuotesAlignmentDirection

	color = input<keyof typeof EQuotesColor>(EQuotesColor.primary)
	borderDirection = input<keyof typeof EQuotesBorderDirection>(EQuotesBorderDirection.none)
	alignmentDirection = input<keyof typeof QuotesAlignmentDirection>(QuotesAlignmentDirection.none)
	quotes = input<boolean, string | boolean>(false, { transform: booleanAttribute })
	background = input<boolean, string | boolean>(false, { transform: booleanAttribute })
	theme = input<UiTheme>(UiTheme.light)
}

enum EQuotesBorderDirection {
	left = 'left',
	right = 'right',
	none = 'none',
}

enum QuotesAlignmentDirection {
	left = 'left',
	right = 'right',
	center = 'center',
	none = 'none',
}

enum EQuotesColor {
	primary = 'primary',
	accent = 'accent',
	warn = 'warn',
	success = 'success',
}
