// Angular Imports
import {
	Component,
	signal,
	ChangeDetectionStrategy,
	inject,
	viewChild,
	effect,
	type ElementRef,
	computed,
	model,
} from '@angular/core'
import { Router, RouterLink } from '@angular/router'
// Shared Imports
import { UiTheme, type NavbarItem, Icons, TranslocoLanguageKey } from '@shared/models'
import {
	ButtonContactmeComponent,
	LinkComponent,
	IconComponent,
	ButtonIconComponent,
} from '@shared/components/independents'
import { UiStore } from '@shared/store'
// Thirdparty Imports
import { TranslocoDirective } from '@jsverse/transloco'

const components = [ButtonContactmeComponent, LinkComponent, IconComponent, ButtonIconComponent]

@Component({
	selector: 'gld-navbar',
	standalone: true,
	imports: [TranslocoDirective, RouterLink, ...components],
	templateUrl: './navbar.component.html',
	styleUrl: './navbar.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent {
	readonly #router = inject(Router)
	readonly #uiStore = inject(UiStore)
	readonly UiTheme = UiTheme
	readonly TranslocoLanguageKey = TranslocoLanguageKey
	readonly Icons = Icons

	theme = computed<UiTheme>(() => this.#uiStore.theme())
	language = computed<TranslocoLanguageKey>(() => this.#uiStore.language())
	navbarIcons = viewChild.required<ElementRef>('navbarIcons')
	open = model<boolean>(false)

	navbarItemsMenu = signal<NavbarItem[]>([
		{ route: ['/me'], label: 'Me', name: 'me', icon: Icons.userSmileLine },
		{ route: ['/resources'], label: 'Resources', name: 'resources', icon: Icons.shapes },
	])

	constructor() {
		// Center navbar icons
		effect(() => {
			const navbarIcons = this.navbarIcons().nativeElement
			const clientWidth = navbarIcons.clientWidth
			navbarIcons.style.setProperty('--icons-width', `${clientWidth}px`)
		})
	}

	toggleTheme(): void {
		if (this.#uiStore.theme() === UiTheme.light) this.#uiStore.changeTheme(UiTheme.dark)
		else this.#uiStore.changeTheme(UiTheme.light)
	}

	toggleLanguage(): void {
		if (this.#uiStore.language() === TranslocoLanguageKey.english)
			this.#uiStore.changeLanguage(TranslocoLanguageKey.spanish)
		else this.#uiStore.changeLanguage(TranslocoLanguageKey.english)
	}

	activeRoute(route: string[]): boolean {
		return this.#router.isActive(route.join('/'), false)
	}

	navigateToRoute(route: string[]) {
		this.#router.navigate(route)
	}

	openSidenav(): void {
		this.#uiStore.openSidenav()
	}
}

