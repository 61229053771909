// Angular Imports
import { Component, signal, ChangeDetectionStrategy, inject, computed } from '@angular/core'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { Router, RouterLink, RouterLinkActive } from '@angular/router'
// Shared Imports
import { UiTheme, Icons, type NavbarItem } from '@shared/models'
import { LinkComponent, IconComponent, ButtonIconComponent } from '@shared/components/independents'
import { UiStore } from '@shared/store'

const components = [LinkComponent, IconComponent, ButtonIconComponent]

@Component({
	selector: 'gld-sidenav',
	standalone: true,
	imports: [RouterLink, RouterLinkActive, ...components],
	templateUrl: './sidenav.component.html',
	styleUrl: './sidenav.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('smoothCollapse', [
			state(
				'hidden',
				style({
					height: 0,
					opacity: 0,
					// overflow: 'hidden',
					visibility: 'hidden',
				}),
			),
			state(
				'show',
				style({
					// overflow: 'hidden',
				}),
			),
			transition('hidden <=> show', animate('100ms')),
		]),
		trigger('smoothRotation', [
			state(
				'0deg',
				style({
					transform: 'rotate(0deg)',
				}),
			),
			state(
				'180deg',
				style({
					transform: 'rotate(180deg)',
				}),
			),
			transition('0deg <=> 180deg', animate('100ms')),
		]),
	],
})
export class SidenavComponent {
	readonly #router = inject(Router)
	readonly #uiStore = inject(UiStore)
	readonly UiTheme = UiTheme
	readonly Icons = Icons

	theme = computed(() => this.#uiStore.theme())
	navbarItems = signal<NavbarItem[]>([
		{ route: ['/me'], label: 'Me', name: 'me', icon: Icons.userSmileLine },
		{
			route: ['/resources'],
			label: 'Resources',
			name: 'resources',
			icon: Icons.shapes,
			opened: false,
			hasSubitems: true,
			subitems: [
				{
					route: ['/resources/libraries'],
					label: 'Libraries',
					name: 'libraries',
					icon: Icons.book2Line,
				},
				{
					route: ['/resources/articles'],
					label: 'Articles',
					name: 'articles',
					icon: Icons.bookReadLine,
				},
				{
					route: ['/resources/fragments'],
					label: 'Code Fragments',
					name: 'code-fragments',
					icon: Icons.codeSlashLine,
				},
			],
		},
	])

	closeSidenav(): void {
		this.#uiStore.closeSidenav()
	}

	onNavigate(route: string[]): void {
		this.#router.navigate(route)
		this.closeSidenav()
	}

	toggleNavbarItem(navbarItem: NavbarItem) {
		this.navbarItems.update((previous: NavbarItem[]) => {
			previous.map((_navbarItem: NavbarItem) => {
				if (_navbarItem === navbarItem) _navbarItem.opened = !_navbarItem.opened
			})
			return previous
		})
	}

	activeRoute(route: string[]): boolean {
		return this.#router.isActive(route.join('/'), false)
	}
}

