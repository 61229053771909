// Store Imports
import { inject } from '@angular/core'
import { patchState, signalStore, withHooks, withMethods, withState } from '@ngrx/signals'
// Shared Imports
import { TranslocoLanguageKey, UiTheme } from '@shared/models'
import { UiService, TranslocoService } from '@shared/services'

export interface UiState {
	theme: UiTheme
	language: TranslocoLanguageKey
	showSidenav: boolean
}

export const initialState: UiState = {
	theme: UiTheme.dark,
	language: TranslocoLanguageKey.english,
	showSidenav: false,
}

export const UiStore = signalStore(
	{ providedIn: 'root' },
	withState(initialState),
	withHooks({
		onInit(store, translocoService = inject(TranslocoService)) {
			translocoService.changeLanguage(store.language())
		},
	}),
	withMethods(
		(store, uiService = inject(UiService), translocoService = inject(TranslocoService)) => ({
			changeTheme: (theme: UiTheme): void => {
				uiService.changeTheme(theme)
				patchState(store, () => ({ theme }))
			},
			changeLanguage: (language: TranslocoLanguageKey): void => {
				translocoService.changeLanguage(language)
				patchState(store, () => ({ language }))
			},
			openSidenav: (): void => {
				patchState(store, () => ({ showSidenav: true }))
			},
			closeSidenav: (): void => {
				patchState(store, () => ({ showSidenav: false }))
			},
		}),
	),
)

