<blockquote class="Quotes Quotes--{{ color() }}" [class.Theme--dark]="theme() === UiTheme.dark">
	<!-- [class.Quotes--quotes]="hasQuotes()" -->
	<!-- [class.Quotes--background]="hasBackground()" -->
	<!-- [class.Quotes--leftborder]="borderDirection() === EQuotesBorderDirection.left" -->
	<!-- [class.Quotes--rightborder]="borderDirection() === EQuotesBorderDirection.right" -->
	<section
		[class.Quotes--left]="alignmentDirection() === QuotesAlignmentDirection.left"
		[class.Quotes--right]="alignmentDirection() === QuotesAlignmentDirection.right"
		[class.Quotes--center]="alignmentDirection() === QuotesAlignmentDirection.center"
	>
		<ng-content />
	</section>
</blockquote>
