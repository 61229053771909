// Angular Imports
import { ChangeDetectionStrategy, Component, input } from '@angular/core'
// Shared Imports
import { UiTheme } from '@shared/models'

@Component({
	standalone: true,
	selector: 'gld-badge',
	imports: [],
	templateUrl: './badge.component.html',
	styleUrl: './badge.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
	readonly UiTheme = UiTheme
	readonly BadgeBorder = BadgeBorder

	label = input.required<string>()
	theme = input<UiTheme>(UiTheme.light)
	border = input<keyof typeof BadgeBorder>(BadgeBorder.squared)
}

enum BadgeBorder {
	rounded = 'rounded',
	squared = 'squared',
}
