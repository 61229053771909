// Angular Imports
import {
	ChangeDetectionStrategy,
	Component,
	booleanAttribute,
	numberAttribute,
	input,
	output,
} from '@angular/core'
// This Module Imports
import { IconComponent } from '../../icon/icon.component'
// Shared Imports
import { ButtonColor, ButtonSize, Icons, IconsSize, UiTheme } from '@shared/models'

@Component({
	selector: 'gld-button',
	standalone: true,
	imports: [IconComponent],
	templateUrl: './button.component.html',
	styleUrl: './button.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
	readonly UiTheme = UiTheme

	name = input.required<string>()
	hasIcon = input<boolean, string | boolean>(false, { transform: booleanAttribute })
	icon = input<Icons>(Icons.addLine)
	iconSize = input<keyof typeof IconsSize>(IconsSize.small)
	iconMoveTopToBotton = input<number, string | number>(0, { transform: numberAttribute })
	iconMoveLeftToRight = input<number, string | number>(0, { transform: numberAttribute })
	color = input<ButtonColor>('blue-to-green')
	size = input<ButtonSize>('normal')
	theme = input<UiTheme>(UiTheme.light)
	clicked = output<void>()

	emitClick() {
		this.clicked.emit()
	}
}

