// Angular Imports
import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core'
// Angular Material Imports
import { MatDialogModule, MatDialog } from '@angular/material/dialog'
// Shared Imports
import { UiTheme } from '@shared/models'
// Thirdparty Imports
import { TranslocoDirective } from '@jsverse/transloco'

const material = [MatDialogModule]

@Component({
	standalone: true,
	selector: 'gld-button-contactme',
	imports: [TranslocoDirective, ...material],
	templateUrl: './button-contactme.component.html',
	styleUrl: './button-contactme.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonContactmeComponent {
	readonly #dialog = inject(MatDialog)
	readonly UiTheme = UiTheme

	theme = input<UiTheme>(UiTheme.light)

	async openContactmeDialog() {
		const { ContactmeDialogComponent } = await import('@shared/components')
		this.#dialog.open(ContactmeDialogComponent, {
			width: '350px',
			autoFocus: false,
		})
	}
}
