// Angular Imports
import { Component, ChangeDetectionStrategy, input, output, booleanAttribute } from '@angular/core'
import { NgTemplateOutlet } from '@angular/common'
import { RouterLink } from '@angular/router'
// Shared Imports
import { UiTheme } from '@shared/models'

@Component({
	selector: 'gld-link',
	standalone: true,
	imports: [NgTemplateOutlet, RouterLink],
	templateUrl: './link.component.html',
	styleUrl: './link.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkComponent {
	readonly UiTheme = UiTheme
	readonly LinkType = LinkType

	redirect = input<string>('')
	external = input<boolean, boolean | string>(false, { transform: booleanAttribute })
	type = input<keyof typeof LinkType>(LinkType.default)
	align = input<LinkAlign>('left')
	theme = input<UiTheme>(UiTheme.light)
	clicked = output<void>()

	emitClick() {
		this.clicked.emit()
	}
}

type LinkAlign = 'left' | 'center' | 'right'

enum LinkType {
	default = 'default',
	decorated = 'decorated',
}
