// Angular Imports
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	inject,
	signal,
} from '@angular/core'
// This Module Imports
import { IconComponent } from '../../icon/icon.component'
import { ButtonIconComponent } from '../button-icon/button-icon.component'
import { Icons } from '@shared/models'

@Component({
	standalone: true,
	selector: 'gld-button-clipboard',
	imports: [IconComponent, ButtonIconComponent],
	templateUrl: './button-clipboard.component.html',
	styleUrl: './button-clipboard.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonClipboardComponent {
	readonly #cdr = inject(ChangeDetectorRef)
	readonly Icons = Icons

	copied = signal<boolean>(false)

	copyToClipboard(): void {
		this.copied.set(true)
		this.#cdr.detectChanges()

		setTimeout(() => {
			this.copied.set(false)
			this.#cdr.detectChanges()
		}, 2000)
	}
}
