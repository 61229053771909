<gld-tile color="blue-to-green" [theme]="theme()">
	<article class="Dialog" [class.Theme--dark]="theme() === UiTheme.dark" *transloco="let transloco">
		<header class="Dialog-header">
			<h2 class="Dialog-header-title">
				{{ transloco('shared.components.compounds.dialogs.contactme.label') }}
			</h2>

			<!-- Close button -->
			<gld-button-icon active name="Close" iconSize="normal" [theme]="theme()" [icon]="Icons.closeLine"
				(clicked)="closeDialog()" />
		</header>

		<section class="Dialog-group">
			<section aria-hidden="true" class="Dialog-preview" (keyup)="openPdf(language())" (click)="openPdf(language())">
				<section class="Dialog-preview-overlay">
					<gld-icon gldIcon [icon]="Icons.eyeLine" size="large" matTooltip="Ver PDF" />
				</section>
				<gld-image class="image" [src]="
						language() === TranslocoLanguageKey.english
							? 'assets/svgs/curriculum/iamgld-cv-english.svg'
							: 'assets/svgs/curriculum/iamgld-cv-spanish.svg'
					" [alt]="transloco('shared.components.compounds.dialogs.contactme.imagePreviewLabel')" />
			</section>

			<section class="Dialog-actions">
				<!-- <gld-button hasIcon size="large" color="purple-to-pink" [theme]="theme()" [icon]="Icons.downloadCloudLine"
					(clicked)="openPdf(language())">
					{{ transloco('shared.components.compounds.dialogs.contactme.downloadLabel') }}
				</gld-button> -->
				<section class="Dialog-socials">
					@for (social of socials(); track social) {
					<gld-link external [redirect]="social.link">
						<gld-button-icon iconSize="normal" [name]="social.name" [theme]="theme()" [icon]="social.icon" />
					</gld-link>
					}
				</section>
			</section>
		</section>
	</article>
</gld-tile>