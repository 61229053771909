<nav class="Navbar" [class.Theme--dark]="theme() === UiTheme.dark">
	<section class="Navbar-content">
		<!-- Brand -->
		<section class="Navbar-brand">
			<gld-link [redirect]="'/'" type="decorated" [theme]="theme()">
				<span>iamgld</span>
			</gld-link>
		</section>

		<!-- Navbar as icons -->
		<section #navbarIcons class="Navbar-icons">
			<section class="Navbar-icons-grid Columns--{{ navbarItemsMenu().length }}">
				@for (navbarItemMenu of navbarItemsMenu(); track navbarItemMenu) {
				<gld-button-icon iconSize="small" [name]="navbarItemMenu.name" [theme]="theme()"
					[active]="activeRoute(navbarItemMenu.route)" [icon]="navbarItemMenu.icon"
					(clicked)="navigateToRoute(navbarItemMenu.route)" />
				}
			</section>
		</section>

		<!-- Change theme button -->
		<gld-button-icon class="Navbar-icontheme" name="change-theme" iconSize="small"
			[icon]="theme() === UiTheme.dark ? Icons.sunLine : Icons.moonClearLine" (clicked)="toggleTheme()" />

		<!-- Change languages button -->
		<gld-button-icon class="Navbar-languages Margin-left--xxxs" iconSize="small" name="change-language"
			[icon]="language() === TranslocoLanguageKey.english ? Icons.englishInput : Icons.translate"
			(clicked)="toggleLanguage()" />

		<!-- Menu button -->
		<gld-button-icon active class="Navbar-iconmenu Margin-left--xxxs" name="burger-menu" iconSize="small"
			[theme]="theme()" [icon]="Icons.menu3Line" (clicked)="openSidenav()" />
	</section>
</nav>