@if (priority()) {
	<img
		gldImageBroken
		priority="true"
		[ngSrc]="src()"
		[alt]="alt()"
		[fill]="true"
		[width]="width()"
		[height]="height()"
	/>
} @else {
	<img
		gldImageBroken
		[ngSrc]="src()"
		[alt]="alt()"
		[fill]="true"
		[width]="width()"
		[height]="height()"
		loading="lazy"
	/>
}
